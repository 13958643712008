import { PlusCircleTwoTone, FilterTwoTone, InfoCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import { SpecialShipmentListParams } from "@api/sharing/specialShipmentApi";
import ImportFileModal from "@component/ImportFileModal";
import PageTitle from "@component/PageTitle";
import IntegratedTable from "@component/Table/IntegratedTable";
import { warehouseCodeOptions } from "@constant/WarehouseCodeValue";
import { StaffPermission, usePermission } from "@hooks/usePermission";
import CreateInvoiceModal, { InvoiceModalType } from "@page/ThroughShipment/ExternalOrder/CreateInvoiceModal";
import {
  backfillInvoice,
  deleteReturnProductApp,
  fetchReturnProductAppList,
  importReturnProductApp,
  returnProductState,
  updateAppListParams,
  updateImportErrorResults,
} from "@redux/sharing/ReturnProductManagementSlice";
import { Row, Col, Space, Button, Tooltip, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledSection, TableWrapper } from "src/styles/common";
import styled from "styled-components";
import ReturnProductFilter from "./Filter";

const ModalContent = styled.section`
  max-height: 150px;
  overflow: auto;
`;

const ReturnProductAppList = () => {
  const dispatch = useDispatch();

  const { isFetching, returnProductAppList, returnProductAppListParams, importErrorResults } = useSelector(
    returnProductState,
  );
  const { results: AppListResults, count } = returnProductAppList;
  const [financeAccess] = usePermission([StaffPermission.CAN_ACCESS_FINANCE_PAGE]);
  const [showImportFilePopup, setShowImportFilePopup] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [createInvoiceId, setCreateInvoiceId] = useState<undefined | number>();

  const handleOnImportSubmit = (file: File) => {
    dispatch(importReturnProductApp(file));
    setShowImportFilePopup(false);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { column, field, order } = sorter;
    let ordering;
    if (column) {
      const snakeField = field.replace(/([A-Z])/g, "_$1").toLowerCase();
      const orderingStr = order === "ascend" ? "" : "-";
      ordering = `${orderingStr}${snakeField}`;
    }
    const params = { ...returnProductAppListParams, ordering };
    dispatch(updateAppListParams(params));
    dispatch(fetchReturnProductAppList(params));
  };

  const handleDeleteApp = (id: number, returnNumber: string) => {
    Modal.confirm({
      title: `確定要作廢 "${returnNumber}"`,
      icon: <ExclamationCircleOutlined />,
      okText: "確定",
      cancelText: "取消",
      onOk: () => handleOnSubmitDelete(id),
    });
  };

  const handleOnSubmitDelete = (id: number) => {
    dispatch(deleteReturnProductApp(id));
  };
  const handleInvoiceSubmit = (returnId: number, payload: any) => {
    dispatch(backfillInvoice({ returnId, payload }));
  };

  const columns: ColumnsType<any> = [
    {
      title: "編號",
      dataIndex: "id",
    },
    {
      title: "通路/平台",
      dataIndex: "platform",
    },
    {
      title: (
        <Space>
          <span>是否入庫</span>
          <Tooltip placement="bottom" title="如果商品會退回到黑貓倉或是藍田倉，則此欄位需填寫「是」，反之則為「否」">
            <InfoCircleTwoTone />
          </Tooltip>
        </Space>
      ),
      dataIndex: "isReturnToWarehouse",
      render: (value) => (value ? "是" : "否"),
      align: "center",
    },
    {
      title: "入庫倉庫",
      dataIndex: "warehouseCode",
      render: (value) => warehouseCodeOptions.find((item) => item.value === value)?.label || "-",
    },
    {
      title: "退貨單號",
      dataIndex: "returnNumber",
    },
    {
      title: "退件人",
      dataIndex: "receiverName",
    },
    {
      title: "退貨商品總數",
      dataIndex: "totalQty",
    },
    {
      title: "建立時間",
      dataIndex: "createdDate",
      sorter: true,
    },
    {
      title: "建立人員",
      dataIndex: "staff",
    },
    {
      title: "實際入庫時間",
      dataIndex: "stockinDate",
    },
    {
      title: "",
      dataIndex: "",
      render: (_, record) => {
        const editDisabled = !!record.destroyedAt || !!record.submittedAt;
        return (
          <Row>
            <Button type="link" onClick={() => window.open(`${window.location.href}/${record.id}`)}>
              {editDisabled ? "檢視" : "編輯"}
            </Button>
            <Button type="link" onClick={() => handleDeleteApp(record.id, record.returnNumber)} disabled={editDisabled}>
              作廢
            </Button>
            {financeAccess && (
              <Button type="link" onClick={() => setCreateInvoiceId(record.id)} disabled={!record.canInvoice}>
                回壓發票
              </Button>
            )}
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchReturnProductAppList({ limit: 20, offset: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (importErrorResults) {
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: (
          <ModalContent>
            {importErrorResults.map((errorItem: any) => {
              return <p>{errorItem}</p>;
            })}
          </ModalContent>
        ),
        okText: "我知道了",
        onOk: () => {
          dispatch(updateImportErrorResults(undefined));
        },
      });
    }
  }, [dispatch, importErrorResults]);

  return (
    <StyledSection>
      <PageTitle title="退貨管理" />
      <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
        <Col span={12}>
          <Space>
            <Button
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => setShowImportFilePopup(true)}
            >
              匯入退貨單
            </Button>
            <Button icon={<FilterTwoTone />} onClick={() => setOpenFilter(!openFilter)}>
              篩選
            </Button>
          </Space>
        </Col>
      </Row>
      <ReturnProductFilter open={openFilter} />
      <TableWrapper>
        <IntegratedTable
          isPagination
          paginationConfig={{
            totalCount: count,
            pageSizeOptions: [20, 50, 100],
            offset: returnProductAppListParams.offset,
            limit: returnProductAppListParams.limit,
            fetchList: (filterParams) => {
              dispatch(fetchReturnProductAppList(filterParams));
            },
            updateFilterParams: (filter: SpecialShipmentListParams) => {
              dispatch(updateAppListParams(filter));
            },
            filterParams: returnProductAppListParams,
          }}
          dataSource={AppListResults}
          loading={isFetching}
          columns={columns}
          scroll={{ x: "max-content" }}
          onChange={handleTableChange}
          onRow={(record: any) => ({
            className: record.destroyedAt ? "ur-disabled-text" : "",
          })}
        />
      </TableWrapper>
      {showImportFilePopup && (
        <ImportFileModal
          csvErrorMessage=""
          closePopup={() => setShowImportFilePopup(false)}
          clearErrorMessage={() => {}}
          handleUploadFile={handleOnImportSubmit}
          openTemplateLink="https://docs.google.com/spreadsheets/d/1owmGzhPvpAWkjet5SRLDdmlQY1_Rom9YVaStPCiw6QM/edit?gid=1577867410#gid=1577867410"
          fileType=".xlsx"
        />
      )}
      {createInvoiceId && (
        <CreateInvoiceModal
          onClose={() => setCreateInvoiceId(undefined)}
          onSubmit={handleInvoiceSubmit}
          invoiceModalType={InvoiceModalType.TYPE}
          orderId={createInvoiceId}
        />
      )}
    </StyledSection>
  );
};

export default ReturnProductAppList;
