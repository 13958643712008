import IntegratedTable from "@component/Table/IntegratedTable";
import {
  fetchInventorySheetDetail,
  fetchInventorySheetProducts,
  inventorySheetState,
  SubmitInventorySheet,
} from "@redux/inventorySheetSlice";
import { Button, Col, Row, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StyledOutlinedWrapper, StyledSection } from "src/styles/common";
import { inventorySheetTypeOptions } from "./InventorySheetFilter";

const InventorySheetDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const inventoryId = Number(id);
  const { isFetching, inventorySheetDetail: details, InventorySheetProducts } = useSelector(inventorySheetState);
  const { results: products, count: productsCount } = InventorySheetProducts;

  const [tableInfo, setTableInfo] = useState({ limit: 20, offset: 0 });

  const handleOnSubmit = () => {
    dispatch(SubmitInventorySheet(inventoryId));
  };
  const columns: ColumnsType<any> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "SKU(品號)",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "商品名稱",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "數量",
      dataIndex: "qty",
      key: "qty",
    },
  ];

  useEffect(() => {
    dispatch(fetchInventorySheetDetail(inventoryId));
  }, [dispatch, inventoryId]);

  useEffect(() => {
    dispatch(fetchInventorySheetProducts({ inventoryId, filter: tableInfo }));
  }, [dispatch, inventoryId, tableInfo]);

  return (
    <Spin spinning={isFetching}>
      <StyledSection>
        <StyledOutlinedWrapper>
          {details && (
            <StyledSection>
              <Row gutter={24} style={{ marginBottom: "24px" }} align="bottom">
                <Col span={4}>單號</Col>
                <Col flex="auto">{details.orderNumber}</Col>
                <Col>
                  <Button onClick={handleOnSubmit} disabled={!!details.destroyedAt || !!details.submittedAt}>
                    提交
                  </Button>
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "24px" }} align="bottom">
                <Col span={4}>倉庫</Col>
                <Col flex="auto">{details.warehouseName}</Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "24px" }}>
                <Col span={4}>類型</Col>
                <Col flex="auto">
                  {inventorySheetTypeOptions.find((option) => option.value === details.purposeType)?.label}
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "24px" }}>
                <Col span={4}>盤點日期</Col>
                <Col flex="auto">{details.invTakingDate}</Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "24px" }}>
                <Col span={4}>備註</Col>
                <Col flex="auto">{details.comments}</Col>
              </Row>
              <IntegratedTable
                isPagination
                paginationConfig={{
                  totalCount: productsCount || 0,
                  pageSizeOptions: [20, 50, 100],
                  offset: tableInfo.offset,
                  limit: tableInfo.limit,
                  updateFilterParams: (filter: typeof tableInfo) => {
                    setTableInfo(filter);
                  },
                  filterParams: tableInfo,
                }}
                columns={columns}
                dataSource={products}
              />
            </StyledSection>
          )}
        </StyledOutlinedWrapper>
      </StyledSection>
    </Spin>
  );
};

export default InventorySheetDetails;
