import { ProfitsStatus, ReceivedStatus } from "@page/VendorListPage/interface";
import downloadCSV from "@utils/downloadCSV";
import { ApiResponse, StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import { Contract, ContractMode } from "./utils/normalizeContract";
import normalizeProduct from "./utils/normalizeProduct";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { SalesChannelItem, SalesChannelType } from "./vendorApi";

const apiClient = new CoreAPI();
interface IProductApi {
  /* 主頁 */
  fetchProductList: (params: FetchProductListParam) => Promise<ProductListResult>;
  batchActivesProducts: (ids: number[], isActive: boolean) => Promise<StandardResponse>;
  batchAddProductVendorsByCSV: (file: File) => Promise<StandardResponse>;
  exportProductVendorsByCSV: (params: FetchProductListParam) => Promise<string>;
  /* 基本資訊Tab */
  fetchProductDetail: (id: number) => Promise<ProductDetail>;
  createProduct: (params: CreateProductDetailParam) => Promise<ProductDetail>;
  updateProduct: (id: number, params: UpdateProductDetailParam) => Promise<ProductDetail>;
  syncToWarehouse: (id: number) => Promise<StandardResponse>;
  /* 品類屬性Tab */
  fetchProductCategoryList: () => Promise<ProductCategory>;
  /* 合作關係 */
  fetchProductVendorList: (params: FetchProductVendorListParam) => Promise<ProductVendorListResult>;
  batchRemoveProductVendors: (params: number[]) => Promise<StandardResponse>;
  fetchVendorList: (params: FetchVendorListParam) => Promise<VendorListResult>;
  createProductVendor: (params: CreateProductVendorParam) => Promise<ProductVendorListItem>;
  updateProductVendor: (vendorListItemId: number, params: UpdateProductVendorParam) => Promise<ProductVendorListItem>;
  fetchContractList: (params: ContractListParam) => Promise<ContractListResult>;
  verifyVPCChangeAlert: (
    vpcId: number,
    price: number,
  ) => Promise<ApiResponse.BasicResponse<{ errMsg: string; results: VPCAlertItem[] }>>;
  /* 銷售方式 */
  fetchProductVendorDetail: (productId: number) => Promise<ProductVendorDetail>;
  fetchSaleMethodList: (productId: number, params: FetchSaleMethodListParam) => Promise<SaleMethodListResult>;
  /* 效期庫存 */
  fetchProductStockList: (productId: number, params: FetchProductStockListParam) => Promise<ProductStockListResult>;
  /* 用 Sku 拿詳情 */
  fetchProductDetailListByFilter: (config: {
    sku?: string;
    nameQ?: string;
  }) => Promise<{
    count: number;
    next: string | null;
    previous: string | null;
    results: ProductDetail[];
  }>;
  fetchTags: (params: FetchTagsParams) => Promise<FetchTagsListResult>;
}

export enum SyncMethodValue {
  SYNC = 1,
  ASYNC = 2,
  ASYNC_TRANSFER_ORDER = 3,
}

export enum ContractModeValue {
  DOMESTIC_BUY_OUT = 1,
  FOREIGN_BUY_OUT = 2,
  WAREHOUSE_CONSIGN = 3,
  TRANSFER_ORDER = 4,
}

export interface ProductListResult {
  count: number;
  previous: string;
  next: string;
  results: ProductListItem[];
}

export interface ProductListItem {
  id: number;
  sku: string;
  owner: {
    id: number;
    name: string;
  };
  isActive: boolean;
  syncStockOption: number;
  name: string;
  brand: {
    id: number;
    name: string;
  };
  canSalesQty: number;
  shelfLife: number;
  canAcceptDays: number;
  canSellDays: number;
  vendorInfo: {
    id: number;
    name: string;
    phone: string;
    contactName: string;
  };
  vpcInfo: {
    commissionRate: number;
    contractInfo: {
      id: number;
      mode: number;
      modeName: string;
      version: string;
    };
    price: number;
    cost: number;
    pcsPerCarton: number;
    deliveryDays: number;
    startDate: string;
    endDate: string;
  };
  weightedCost: number;
  avgDailySalesVolume: number;
  totalTurnoverDays: number;
  isSyncWarehouse: boolean;
}

export interface FetchProductListParam {
  sku?: string;
  nameQ?: string;
  salesPageId?: number;
  owner?: number;
  vendor?: number;
  brand?: string;
  brandId?: number;
  isActive?: number; // 上架 下架
  syncOption?: number;
  categoryLevel1?: number;
  categoryLevel2?: number;
  categoryLevel3?: number;
  lowStockRisk?: boolean;
  attrNameQ?: string;
  limit: number;
  offset: number;
  salesChannel?: SalesChannelType.URMART;
  warehouseCode?: string;
}
export interface ProductVendorListResult {
  count: number;
  previous: string;
  next: string;
  results: ProductVendorListItem[];
}
export interface ProductVendorListItem {
  canEdit: boolean;
  id: number;
  vendorInfo: {
    id: number;
    name: string;
    phone: string;
    contactName: string;
  };
  price: number;
  cost: number;
  contract: number;
  mode: number;
  modeName: string;
  commissionRate: number;
  pcsPerCarton: number;
  deliveryDays: number;
  startDate: string;
  endDate: string;
  product: ProductDetail;
  deductionInfo: {
    deductions: {
      deductType: number;
      displayName: string;
      rate: number;
    }[];
    totalDeductionRate: number;
  };
  vpcId: number;
  salesChannel: SalesChannelItem;
}
export interface FetchProductVendorListParam {
  limit: number;
  offset: number;
  productId?: number;
  withDeduction?: boolean;
  sku?: string;
  isActive?: boolean;
  isHidden?: boolean;
  salesChannel?: number; // 通路 1為電商 2為國館巨蛋店
}

export interface ProductCategoryItem {
  id: number;
  name: string;
  parent: number;
  children: ProductCategoryItem[];
}
export interface ProductCategory {
  level1: {
    [id: number]: ProductCategoryItem;
  };
  level2: {
    [id: number]: ProductCategoryItem;
  };
  level3: {
    [id: number]: ProductCategoryItem;
  };
}

export interface VendorListResult {
  count: number;
  previous: string;
  next: string;
  results: VendorListItem[];
}
export interface VendorListItem {
  id: number;
  name: string;
  phone: string;
  majorContact: {
    email: string;
    id: number;
    isMajor: boolean;
    name: string;
    phone: string;
    title: string;
    vendor: number;
  };
  productQty: number;
  owner: {
    id: number;
    name: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
    createdAt: string;
  };
  isActive: boolean;
  createdAt: string;
  endAt: string;
  currency: number;
}
export interface FetchVendorListParam {
  limit: number;
  offset: number;
  nameQ?: string;
  owner?: number;
  startDateAfter?: string; // yyyy-mm-dd
  startDateBefore?: string;
  endDateAfter?: string;
  endDateBefore?: string;
  profitStatus?: ProfitsStatus;
  received?: ReceivedStatus;
}

export interface CreateProductDetailParam {
  owner: number;
  name: string;
  sku: string;
  brand: number;
  shelfLife?: number;
  syncOption: number;
  isSyncWarehouse?: boolean;
  isActive: boolean;
  stockQty?: number;
  productCategory?: number;
  processingFee?: number;
  tags?: number[];
  weightedCost?: number;
  length: number;
  width: number;
  height: number;
  weight: number;
  cartonLength?: number | null;
  cartonWidth?: number | null;
  cartonHeight?: number | null;
}

export type UpdateProductDetailParam = CreateProductDetailParam;
export interface ProductDetail {
  id?: number;
  isActive: boolean;
  syncStockOption: number;
  owner: {
    id: number;
    name: string;
  };
  sku: string;
  name: string;
  brand: {
    id: number;
    name: string;
  };
  shelfLife?: number;
  processingFee?: number;
  canSellDays?: number;
  canAcceptDays?: number;
  weight: number;
  length: number;
  width: number;
  height: number;
  cartonLength?: number;
  cartonWidth?: number;
  cartonHeight?: number;
  weightedCost?: number;
  stockQty?: number;
  holdStockQty?: number;
  retentionStockQty?: number;
  canSalesQty?: number;
  canReturnBadQty?: number;
  categories: {
    id: number;
    name: string;
    level: number;
  }[];
  isSyncWarehouse: boolean;
  tags: {
    tag: number;
    typeName: string;
    typeCode: number;
    typeId: number;
  }[];
  vpcInfo?: {
    commissionRate: number;
    contractInfo: { id: number; mode: ContractMode; modeName: string; version: string };
    cost: number;
    deliveryDays: number;
    endDate: string | null;
    pcsPerCarton: number;
    price: number;
    startDate: string | null;
  };
  salesChannelCost?: { [key: number]: number };
}

export interface CategoryItem {
  id: number;
  name: string;
}

export interface CreateProductVendorParam {
  vendor: number;
  product: number;
  contract?: number;
  startDate?: string;
  endDate: string | null;
  pcsPerCarton: number;
  price: number;
  commissionRate?: number;
  cost?: number;
  deliveryDays?: number;
  salesChannel: number;
}
export type UpdateProductVendorParam = CreateProductVendorParam;

export interface ContractListResult {
  count: number;
  previous: string;
  next: string;
  results: Contract[];
}

export interface ContractListParam {
  limit: number;
  offset: number;
  mode?: number;
}
export interface SaleMethodListResult {
  count: number;
  previous: string;
  next: string;
  results: SaleMethodListItem[];
}

export interface ProductVendorDetail {
  commissionRate: number;
  contract: number;
  cost: number;
  deliveryDays: number;
  endDate: string;
  id: number;
  mode: number;
  modeName: string;
  pcsPerCarton: number;
  price: number;
  product: {
    brand: {
      id: number;
      name: string;
    };
    canSellDays: number;
    canSalesQty: number;
    canAcceptDays: number;
    categories: {
      id: number;
      name: string;
      level: number;
    }[];
    height: number;
    holdStockQty: number;
    id: number;
    isActive: boolean;
    isSyncWarehouse: boolean;
    length: number;
    name: string;
    owner: {
      id: number;
      name: string;
    };
    retentionStockQty: number;
    shelfLife: number;
    sku: string;
    stockQty: number;
    syncStockOption: number;
    tags: number[];
    weight: number;
    weightedCost: number;
    width: number;
  };
  startDate: string;
  vendorInfo: {
    id: number;
    name: string;
    phone: string;
    contactName: string;
  };
  vpcId: number;
}
export interface SaleMethodListItem {
  salesPageInfo: {
    id: number;
    name: string;
  };
  salesPlanInfo: {
    name: string;
    canBuyCount: number;
    specialPrice: number;
    isActive: boolean;
  };
  productInfo: {
    cost: number;
    price: number;
    commissionRate: number;
  };
}
export interface FetchSaleMethodListParam {
  limit: number;
  offset: number;
}
export interface ProductStockListResult {
  count: number;
  previous: string;
  next: string;
  results: ProductStockListItem[];
}
export interface ProductStockListItem {
  product: number;
  stockQty: number;
  effectiveDate: string;
  qualified: boolean;
}
export interface FetchProductStockListParam {
  limit: number;
  offset: number;
}
export interface FetchTagsParams {
  name?: string;
  nameQ?: string;
  typeNameQ?: string;
  isActive?: string;
  categoryIds?: string;
  typeCode?: number;
}
export interface FetchTagsList {
  id: number;
  tagType: string;
  name: string;
  icon: string | null;
  isActive: boolean;
  taggedItemCount: number;
}
export interface FetchTagsListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: FetchTagsList[];
}

export type VPCAlertItem = {
  pageId: number;
  pageName: string;
  plans: {
    planId: number;
    planName: string;
    options: {
      name: string;
      price: number;
      newPrice: number;
      productId: number;
    }[];
  }[];
};

const productApi: IProductApi = {
  fetchProductList: async (params) => {
    const {
      brand,
      sku,
      vendor,
      brandId,
      owner,
      categoryLevel1,
      categoryLevel2,
      categoryLevel3,
      isActive,
      syncOption,
      lowStockRisk,
      ...paramsArgs
    } = params;

    // 找到最下層的category id
    const category = [categoryLevel3, categoryLevel2, categoryLevel1].find((ctgy) => ctgy && ctgy > 0);
    const getParams = transformCamelToSnake({
      ...paramsArgs,
      skus: sku === "" ? undefined : sku,
      vendor: vendor && vendor < 0 ? undefined : vendor,
      brandNameQ: brand === "" ? undefined : brand,
      brandId: brandId && brandId < 0 ? undefined : brandId,
      owner: owner && owner < 0 ? undefined : owner,
      isActive: isActive && isActive < 0 ? undefined : Boolean(isActive),
      syncStockOption: syncOption && syncOption < 0 ? undefined : syncOption,
      productCategory: category,
      lowStockRisk: lowStockRisk ? 1 : 0,
      // salesChannel: salesChannel === SalesChannelType.URMART ? undefined : salesChannel, // 後端要求如為 urmart 不傳
    });
    const response = await apiClient.getData("/manage/product/products/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchActivesProducts: async (ids, isActive) => {
    const putParams = {
      products: ids.map((id) => ({
        id,
        is_active: isActive,
      })),
    };
    const response = await apiClient.putData("/manage/product/products/batch-actives/", putParams);
    return response.data;
  },
  fetchProductVendorList: async (params) => {
    const { productId, ...paramsArg } = params;
    const getParams = transformCamelToSnake({
      ...paramsArg,
      product: productId,
      withDeduction: Boolean(params.withDeduction),
    });
    const response = await apiClient.getData("/manage/product/product-vendors/", getParams);
    return normalizeProduct.productVendorList(response.data.result);
  },
  batchAddProductVendorsByCSV: async (file: File) => {
    const formData = new FormData();
    formData.append("csv_file", file);

    const response = await apiClient.postData("/manage/product/products/csv-upload/", formData);
    return response.data;
  },
  exportProductVendorsByCSV: async (params) => {
    const {
      sku,
      nameQ,
      vendor,
      brand,
      brandId,
      owner,
      isActive,
      syncOption,
      categoryLevel1,
      categoryLevel2,
      categoryLevel3,
      lowStockRisk,
      attrNameQ,
      salesChannel,
    } = params;

    // 找到最下層的category id
    const category = [categoryLevel3, categoryLevel2, categoryLevel1].find((ctgy) => ctgy && ctgy > 0);

    const getParams = {
      skus: sku === "" ? undefined : sku,
      nameQ: nameQ === "" ? undefined : nameQ,
      vendor: vendor && vendor < 0 ? undefined : vendor,
      brand_name_q: brand === "" ? undefined : brand,
      brand_id: brandId && brandId < 0 ? undefined : brandId,
      owner: owner && owner < 0 ? undefined : owner,
      is_active: isActive !== undefined && isActive < 0 ? undefined : Boolean(isActive),
      sync_stock_option: syncOption && syncOption < 0 ? undefined : syncOption,
      product_category: category,
      low_stock_risk: lowStockRisk ? 1 : 0,
      attr_name_q: attrNameQ === "" ? undefined : attrNameQ,
      sales_channel: salesChannel,
    };

    const response = await apiClient.getData("/manage/product/products/csv-download/", getParams);
    if (!response.data.result) {
      downloadCSV(response);
      return "Success";
    }
    return response.data.result;
  },
  batchRemoveProductVendors: async (params) => {
    const response = await apiClient.putData("/manage/product/product-vendors/batch-delete/", {
      ids: params,
    });
    return response.data;
  },
  fetchProductCategoryList: async () => {
    const getParams = {
      with_product_count: 0,
    };
    const response = await apiClient.getData("/manage/product/product-categories/", getParams);
    return normalizeProduct.categoryList(response.data.result);
  },
  fetchVendorList: async (params) => {
    const {
      limit,
      offset,
      nameQ,
      owner,
      startDateAfter,
      startDateBefore,
      endDateAfter,
      endDateBefore,
      profitStatus,
      received,
    } = params;
    const getParams = {
      limit,
      offset,
      name_q: nameQ,
      owner,
      start_date_after: startDateAfter,
      start_date_before: startDateBefore,
      end_date_after: endDateAfter,
      end_date_before: endDateBefore,
      profit_status: profitStatus,
      received: typeof received === "number" ? received : undefined,
    };
    const response = await apiClient.getData("/manage/vendor/vendors/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductDetail: async (id) => {
    const response = await apiClient.getData(`/manage/product/products/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductDetailListByFilter: async (config) => {
    const response = await apiClient.getData("/manage/product/products/", transformCamelToSnake(config));
    return transformAPIKeyToCamel(response.data.result);
  },
  createProduct: async (param) => {
    const requestParam = transformCamelToSnake(param);
    const response = await apiClient.postData("/manage/product/products/", requestParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateProduct: async (id, param) => {
    const requestParam = transformCamelToSnake(param);
    const response = await apiClient.patchData(`/manage/product/products/${id}/`, requestParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  syncToWarehouse: async (id) => {
    const response = await apiClient.getData(`/manage/product/products/${id}/sync-to-warehouse/`, {});
    return response.data;
  },
  createProductVendor: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.postData("/manage/product/product-vendors/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateProductVendor: async (id, params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.patchData(`/manage/product/product-vendors/${id}/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchContractList: async (params) => {
    const response = await apiClient.getData("/manage/vendor/contracts/", params);
    return transformAPIKeyToCamel(response.data.result);
  },
  verifyVPCChangeAlert: async (vpcId: number, price: number) => {
    const response = await apiClient.postData(`/manage/vendor/vendor-product-contracts/${vpcId}/change-alert/`, {
      new_price: price,
    });
    return transformAPIKeyToCamel(response.data);
  },
  fetchProductVendorDetail: async (productId: number) => {
    const response = await apiClient.getData(`/manage/product/product-vendors/${productId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSaleMethodList: async (productId) => {
    const getParams = {
      product: productId,
    };

    const response = await apiClient.getData("/manage/product/product-options/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductStockList: async (productId, params) => {
    const response = await apiClient.getData("/manage/stock/effectivenesses/", {
      product: productId,
      ...params,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchTags: async (params) => {
    const response = await apiClient.getData("/manage/tag/tags/", {
      ...transformCamelToSnake(params),
      type_code: 4,
      limit: 100,
      offset: 0,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default productApi;
