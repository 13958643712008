/* eslint-disable no-undef */
import { FetchProductListParam, SyncMethodValue } from "@api/productApi";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import {
  clearProductListFilter,
  fetchBrandList,
  fetchProductCategoryList,
  fetchProductList,
  fetchTags,
  fetchVendorList,
  initialState,
  loadMoreBrandList,
  loadMoreVendorList,
  updateProductListFilter,
} from "@redux/productSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Checkbox, Input, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.form`
  margin-bottom: 20px;
`;
const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 30px;
  row-gap: 17px;
`;

const Row = styled.div<{ margin?: number }>`
  display: flex;
  align-items: center;

  margin-bottom: ${({ margin }) => `${margin}px`};
`;

const RowTitle = styled.div`
  flex: 3;
  min-width: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;

const FirstRowTitle = styled.div`
  flex: 0.5;
  min-width: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const RowContent = styled.div`
  flex: 7;
  min-width: 0;

  margin-left: 12px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const ProductCategoryRow = styled.div<{ row: number }>`
  grid-column: span 1;
  grid-row-start: ${({ row }) => row};
  display: flex;
  align-items: center;
  min-width: 0;
`;
const ProductCategoryRowTitle = styled.div`
  flex: 3;
  white-space: nowrap;
`;
const ProductCategoryRowContent = styled.div`
  flex: 7;
  margin-left: 12px;
  display: flex;
  min-width: 0;
`;
const ProductCategorySelect = styled(Select)`
  width: 300px;
  && .ant-select-selector {
    width: 300px;
  }
`;
const Footer = styled.div`
  margin-top: 14px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonContainer = styled.div``;
const ClearFilterButton = styled(Button)`
  margin-right: 8px;
`;

const statusList = [
  {
    label: "請選擇",
    value: -1,
  },
  {
    label: "上架",
    value: 1,
  },
  {
    label: "下架",
    value: 0,
  },
];
export const syncMethodList = [
  {
    label: "請選擇",
    value: -1,
  },
  {
    label: "同步",
    value: SyncMethodValue.SYNC,
  },
  {
    label: "不同步",
    value: SyncMethodValue.ASYNC,
  },
  {
    label: "轉單不同步",
    value: SyncMethodValue.ASYNC_TRANSFER_ORDER,
  },
];

const Filter: FC = () => {
  const productListFilter = useSelector((state: RootState) => state.product.productListFilter);
  const brandListResult = useSelector((state: RootState) => state.product.brandListResult);
  const vendorListResult = useSelector((state: RootState) => state.product.vendorListResult);
  const staffList = useSelector((state: RootState) => state.staffs.bdStaffData);
  const productCategory = useSelector((state: RootState) => state.product.productCategory);
  const tagListResult = useSelector((state: RootState) => state.product.tagListResult);
  const dispatch = useAppDispatch();

  const [localFilter, setLocalFilter] = useState<FetchProductListParam>(initialState.productListFilter);

  const [skuSet, setSkuSet] = useState<Set<string>>(new Set());
  const needResetData = useRef<boolean>(false);
  const {
    salesPageId,
    nameQ,
    vendor,
    brandId,
    owner,
    isActive,
    syncOption,
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
    lowStockRisk,
    attrNameQ,
  } = localFilter;

  useEffect(() => {
    dispatch(fetchBrandList(""));
    dispatch(fetchVendorList(""));
    dispatch(fetchProductCategoryList());
  }, [dispatch]);

  useEffect(() => {
    setLocalFilter(productListFilter);
  }, [productListFilter]);

  const toggleShortageRisk = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setLocalFilter((prev) => ({
      ...prev,
      shortageRisk: checked,
    }));
  };

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreVendorList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  const vendorOptions = useMemo(() => {
    const { next, results } = vendorListResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id}>
        {vdr.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [vendorListResult, handleOnVendorLoadMore]);

  const staffOptions = useMemo(() => {
    const options = staffList.map((staff) => (
      <Select.Option key={staff.id} value={staff.id}>
        {staff.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    return options;
  }, [staffList]);

  const categoryLevel1Options = useMemo(() => {
    const options = Object.values(productCategory.level1).map((ctgryItem) => (
      <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇第一層品類
      </Select.Option>,
    );
    return options;
  }, [productCategory]);

  const categoryLevel2Options = useMemo(() => {
    if (categoryLevel1 && categoryLevel1 > 0) {
      const level2Options = productCategory.level1[categoryLevel1]!;
      const options = level2Options.children.map((ctgryItem) => (
        <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
      ));
      options.unshift(
        <Select.Option key={-1} value={-1}>
          請選擇
        </Select.Option>,
      );
      return options;
    }
    return (
      <Select.Option key={-1} value={-1}>
        請先選擇第一層品類，再選擇第二層品類
      </Select.Option>
    );
  }, [productCategory, categoryLevel1]);

  const categoryLevel3Options = useMemo(() => {
    if (categoryLevel2 && categoryLevel2 > 0) {
      const level3Options = productCategory.level2[categoryLevel2]!;
      const options = level3Options.children.map((ctgryItem) => (
        <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
      ));
      options.unshift(
        <Select.Option key={-1} value={-1}>
          請選擇
        </Select.Option>,
      );
      return options;
    }
    return (
      <Select.Option key={-1} value={-1}>
        請先選擇第二層品類，再選擇第三層品類
      </Select.Option>
    );
  }, [productCategory, categoryLevel2]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name: inputName } = e.target;
    setLocalFilter((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const handleOnVendorSearch = useDebounce((value: string) => {
    dispatch(fetchVendorList(value));
  }, 300);

  const handleAttributeTagSearch = useDebounce((value: string) => {
    dispatch(
      fetchTags({
        nameQ: value,
      }),
    );
  }, 300);

  const handleOnSelect = (title: string) => (value: SelectValue) => {
    switch (title) {
      case "categoryLevel1":
        // 選第一品類 刷新第二三層品類
        setLocalFilter((prev) => ({
          ...prev,
          [title]: value as number,
          categoryLevel2: -1,
          categoryLevel3: -1,
        }));
        break;
      case "categoryLevel2":
        // 選第二品類 刷新第三層品類
        setLocalFilter((prev) => ({
          ...prev,
          [title]: value as number,
          categoryLevel3: -1,
        }));
        break;
      default:
        setLocalFilter((prev) => ({
          ...prev,
          [title]: value,
        }));
    }
  };

  const handleOnSaveFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const skuArr = Array.from(skuSet);
    const params = {
      ...localFilter,
      sku: skuArr.toLocaleString(),
    };

    dispatch(updateProductListFilter(params));
    dispatch(fetchProductList());
  };

  const handleOnClearFilter = () => {
    setLocalFilter(initialState.productListFilter);
    const newSet = new Set("");
    setSkuSet(newSet);
    dispatch(clearProductListFilter());
    dispatch(fetchProductList());
  };

  const handleOnSkuChange = (e: any) => {
    setSkuSet(e);
  };

  const onDeselect = (e: any) => {
    const newSet = new Set(skuSet);
    if (needResetData.current) {
      needResetData.current = false;
      newSet.add(e);
      setSkuSet(newSet);
    }
  };

  const onMouseLeave = () => {
    needResetData.current = false;
  };

  return (
    <Wrapper onSubmit={handleOnSaveFilter}>
      <Row margin={16}>
        <FirstRowTitle>SKU(品號)</FirstRowTitle>
        <RowContent>
          <Select
            allowClear
            value={Array.from(skuSet)}
            mode="tags"
            onMouseLeave={onMouseLeave}
            style={{ width: "100%" }}
            onDeselect={onDeselect}
            onChange={handleOnSkuChange}
            onInputKeyDown={(e) => {
              if (e.key === "Enter") {
                needResetData.current = true;
              }
            }}
          />
        </RowContent>
      </Row>

      <FilterWrapper>
        <Row>
          <RowTitle>商品名稱</RowTitle>
          <RowContent>
            <Input value={nameQ} name="nameQ" onChange={handleOnChange} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>銷售業ID</RowTitle>
          <RowContent>
            <Input value={salesPageId} name="salesPageId" onChange={handleOnChange} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>廠商</RowTitle>
          <RowContent>
            <StyledSelect
              showSearch
              filterOption={false}
              value={vendor}
              onChange={(value) => handleOnSelect("vendor")(value as SelectValue)}
              onSearch={handleOnVendorSearch}
            >
              {vendorOptions}
            </StyledSelect>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>品牌</RowTitle>
          <RowContent>
            <StyledSelect
              showSearch
              filterOption={false}
              value={brandId}
              onChange={(value) => handleOnSelect("brandId")(value as SelectValue)}
              onSearch={handleOnBrandSearch}
            >
              {brandOptions}
            </StyledSelect>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>負責人</RowTitle>
          <RowContent>
            <StyledSelect value={owner} onChange={(value) => handleOnSelect("owner")(value as SelectValue)}>
              {staffOptions}
            </StyledSelect>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>狀態</RowTitle>
          <RowContent>
            <StyledSelect
              value={isActive}
              options={statusList}
              onChange={(value) => handleOnSelect("isActive")(value as SelectValue)}
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>倉庫資訊同步方式</RowTitle>
          <RowContent>
            <StyledSelect
              value={syncOption}
              options={syncMethodList}
              onChange={(value) => handleOnSelect("syncOption")(value as SelectValue)}
            />
          </RowContent>
        </Row>
        <ProductCategoryRow row={3}>
          <ProductCategoryRowTitle>屬性</ProductCategoryRowTitle>
          <ProductCategoryRowContent>
            <ProductCategorySelect
              showArrow
              showSearch
              filterOption={false}
              value={attrNameQ}
              onChange={(value) => handleOnSelect("attrNameQ")(value as SelectValue)}
              onSearch={handleAttributeTagSearch}
            >
              {tagListResult.results.map((item) => (
                <Select.Option value={item.name}>{item.name}</Select.Option>
              ))}
            </ProductCategorySelect>
          </ProductCategoryRowContent>
        </ProductCategoryRow>
        <ProductCategoryRow row={4}>
          <ProductCategoryRowTitle>第一層品類</ProductCategoryRowTitle>
          <ProductCategoryRowContent>
            <ProductCategorySelect
              value={categoryLevel1}
              onChange={(value) => handleOnSelect("categoryLevel1")(value as SelectValue)}
            >
              {categoryLevel1Options}
            </ProductCategorySelect>
          </ProductCategoryRowContent>
        </ProductCategoryRow>
        <ProductCategoryRow row={5}>
          <ProductCategoryRowTitle>第二層品類</ProductCategoryRowTitle>
          <ProductCategoryRowContent>
            <ProductCategorySelect
              value={categoryLevel2}
              disabled={!categoryLevel1 || categoryLevel1 < 0}
              onChange={(value) => handleOnSelect("categoryLevel2")(value as SelectValue)}
            >
              {categoryLevel2Options}
            </ProductCategorySelect>
          </ProductCategoryRowContent>
        </ProductCategoryRow>
        <ProductCategoryRow row={6}>
          <ProductCategoryRowTitle>第三層品類</ProductCategoryRowTitle>
          <ProductCategoryRowContent>
            <ProductCategorySelect
              value={categoryLevel3}
              disabled={!categoryLevel2 || categoryLevel2 < 0}
              onChange={(value) => handleOnSelect("categoryLevel3")(value as SelectValue)}
            >
              {categoryLevel3Options}
            </ProductCategorySelect>
          </ProductCategoryRowContent>
        </ProductCategoryRow>
      </FilterWrapper>
      <Footer>
        <Checkbox value={lowStockRisk} onChange={toggleShortageRisk}>
          缺貨風險
        </Checkbox>
        <ButtonContainer>
          <ClearFilterButton onClick={handleOnClearFilter}>清除篩選條件</ClearFilterButton>
          <Button type="primary" htmlType="submit">
            套用
          </Button>
        </ButtonContainer>
      </Footer>
    </Wrapper>
  );
};

export default Filter;
